<template>
  <div>

    <b-row>
      <b-col class="col-12 col-sm-4">
        <b-icon-collection-play class="largeicontop" style="float:left;margin-right:25px" />
        <h4 style="margin-top:5px;">Subscriptions</h4>
      </b-col>

      <b-col class="col-12 col-sm-4 offset-sm-4 mt-3 mt-sm-0 mb-3 text-right">
        <b-button variant="outline-success" size="sm" block @click="menuLink('subscriptionadd')"><b-icon-basket
            style="margin-right:5px" /> Buy Subscriptions</b-button>
      </b-col>
    </b-row>

    <b-row class="mt-5 border pt-3 pb-5" v-if="stocksubs.length == 0 && display">
      <b-col class="col-12 mt-3 text-center">
        <h5>No available stock subscriptions</h5>
      </b-col>
      <b-col class="col-12 col-sm-8 offset-sm-2 mt-0 text-center">
        <b-button variant="outline-info" block size="lg" @click.stop="menuLink('subscriptionadd')">Buy
          Subscription</b-button>
      </b-col>
    </b-row>

    <b-row class="mt-0">

      <b-col class="col-12" v-if="stocksubs.length > 0">
        <h5>Available Stock Subscriptions</h5>
      </b-col>

      <b-col class="col-12 col-sm-6 col-xl-4 mt-3 p-0" v-for="stocksub in stocksubs" :key="stocksub.value">

        <div class="boxnohover" style="min-height:80px">
        
          <b-row class="p-3">
            <b-col class="col-2 text-right">
              <h3><b-icon-collection-play /></h3>
            </b-col>

            <b-col class="col-10">
              <h6>{{ stocksub.text }}</h6>
              {{  stocksub.substatustext }}
            </b-col>
          </b-row>
        </div>
      </b-col>

    </b-row>

    <b-row class="mt-3">

      <b-col class="col-12 mt-3" v-if="stocksims.length > 0">
        <h5>Available Stock Sims</h5>
      </b-col>
      <b-col class="col-12 col-sm-6 col-xl-4 mt-3 p-0" v-for="simm in stocksims" :key="simm.value">
        <div class="boxnohover" style="min-height:80px;">
          <b-row class="p-3">
            <b-col class="col-2 text-right">
              <h3><b-icon-phone /></h3>
            </b-col>
            <b-col class="col-10">
              <h6>{{ simm.text }}</h6>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col v-if="subs.length > 0" class="col-12" >
        <h5>Current Subscriptions</h5>
      </b-col>
      <b-col v-else class="col-12" >
        <b-row align-h="center">
          <b-col>
            <h5>No Active Subscriptions</h5>
          </b-col>
        </b-row>
      </b-col>

      <b-col class="col-12 col-sm-6 col-xl-4 mt-3 p-0" v-for="sub in subs" :key="sub.value" @click.stop="viewSub(sub.rover)">

        <div class="box" style="min-height:200px">
       
          <b-row class="pt-3 pl-3 pr-3">
            <b-col class="col-10">
              <h5>{{ sub.username }}</h5>
              <h6>{{ sub.subscription }}</h6>
            </b-col>
            <b-col class="col-2 text-right">
              <h3><b-icon-collection-play /></h3>
            </b-col>

            <b-col class="col-12" v-if="sub.startdate">
              {{ sub.startdate }} - {{ sub.enddate }}
            </b-col>

            <b-col class="col-12 mt-1" v-if="sub.machine">
              {{ sub.machine.maker }} {{ sub.machine.model }}
            </b-col>
            <b-col class="col-12" v-if="sub.machine[0].regnum">
              {{ sub.machine[0].regnum }}
            </b-col>
            <b-col class="col-12" v-else>
              -
            </b-col>
            <b-col class="col-12" v-if="sub.nosimm = 0">
              ICCID: {{ sub.simmdetail.number }}
            </b-col>
          </b-row>

          <b-row class="mt-3 mb-3">
            <b-col class="col-12 text-right">
              <b-button variant="outline-warning" block @click.stop="activateSub(sub)"
                v-if="!sub.rover">ACTIVATE</b-button>
              <div class="statusbox" v-if="sub.status != 2" :style="{ backgroundColor: sub.color }">{{ sub.statustext }}
                - {{ sub.days }} days remaining</div>
              <div class="statusbox" v-else :style="{ backgroundColor: sub.expirycolor }">{{ sub.statustext }} - Starts
                {{ sub.startdate }}</div>
            </b-col>

            <b-col class="col-12 col-sm-10 offset-sm-1 mt-3 mb-3" v-if="sub.days < 7">
              <b-button variant="outline-warning" block @click.stop="renewChoice(sub.rover)">Renew subscription</b-button>
            </b-col>
          </b-row>


        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3 mb-5">
      <b-col class="col-12 text-right"></b-col>
    </b-row>  

    <b-modal ref="renew-choice" title="Renew" @hide="closeModal" hide-footer centered content-class="darkmodal">
      <SubChoice />
    </b-modal>

    <!--reload sub data-->
    <LoadCompanyData :reloaddata="loadDataType" :dialog="false" />

  </div>
</template>

<script>
import SubChoice from './SubChoice.vue';
import LoadCompanyData from './LoadCompanyData'
export default {
  name: 'SubscriptionsList',
  components: {
    SubChoice,
    LoadCompanyData
  },
  data() {
    return {
      token: '',
      subs: [],
      homeSubs: [],
      subscount: 99,
      stocksel:0,
      selectedsub:{},
      stocksubs: [],
      stocksims: [],
      rovers: [],
      rover: {},
      selectedrover: {},
      sub: {},
      edit: false,
      roverid: 0,
      newrover: 0,
      display: false,
      modaltitle: "",
      status: 0,
      types: [
        { value: 0, text: 'Show All' },
        { value: 3, text: 'Filter Active' },
        { value: 4, text: 'Filter Expired' },
        { value: 2, text: 'Filter Pending' }
      ],
      title: '',
      loadDataType:0,
      
    };
  },

  created() {   
    
    this.token = this.$store.getters.token
    this.userid = this.$store.getters.user.id
    this.loadStoreData()
  },
  
  methods: {

    loadStoreData(){
      this.subs = this.$store.getters.subs
      this.stocksims = this.$store.getters.stocksims
      this.stocksubs = this.$store.getters.stocksubs
      this.rovers = this.$store.getters.rovers
    },

    renewChoice(roverid){
      this.selectedrover = this.rovers.filter(rover=>rover.value == roverid)[0]
      this.$store.commit('setselectedrover',this.selectedrover)
      if (this.stocksubs.length != 0) {
        this.$refs['renew-choice'].show()
      } else this.menuLink('subscriptionadd') // goto buy subs
    },

    viewSub(id) {
      this.$router.push({ name: 'subscription', params: { id: id } }).catch(() => { })
     }, 

    menuLink(name) {
      this.$store.commit('setselectedrover','')
      this.$store.commit('setselectedsub','')
      this.$router.push({ name: name, params: {} }).catch(() => { })
    },

    closeModal(){
      this.stocksubs.forEach(element => {element.substatustext = 'Available'})
    }

    // reloadSubs(){ RB 20/9
    //   //grabs sub data when you enter page if its been long enough after last update
    //   const reloadSubsOnEnterPeriodSeconds = 60;
    //   const periodMilis = reloadSubsOnEnterPeriodSeconds * 1000;
    //   setTimeout( () => {
    //     //make a new time stamp and fetch the last time from cache
    //     const timeNow = Date.now();
    //     const lastSubFetch = sessionStorage.getItem('lastSubFetch');

    //     //check timestamp difference, if too small exit
    //     if (timeNow - lastSubFetch < periodMilis){ return;}

    //     //save timestamp, trigger and reload page 
    //     this.loadDataType = 3;
    //     sessionStorage.setItem('lastSubFetch', timeNow);

    //     //short delay to let the LoadCompanyData Comp trigger.
    //     setTimeout( () => {
    //       this.loadStoreData();
    //     },1000);
        
    //   },1000);
    // },

  }

};

</script>